import { render, staticRenderFns } from "./s01-representante.vue?vue&type=template&id=7ac158db&scoped=true&"
import script from "./s01-representante.vue?vue&type=script&lang=js&"
export * from "./s01-representante.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac158db",
  null
  
)

export default component.exports