
<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">7-</div>-->
                        <div class="flex-column padding-div w-100">
                            <div class="fs-32px fw-700 color-dark">Número de celular do dependente</div>
                            <div class="single-input">
                                <v-text-field-simplemask v-model="value" class="input-caps" required :rules="[rules.required, rules.celular]"
                                                         v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                         v-bind:options="{
        inputMask: '(##) #####-####',
        outputMask: '###########',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" @keyup.native.enter="send" v-on:keyup.enter="send"
                                                         v-bind:focus="focus"
                                                         v-on:focus="focus = false" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="send" class="button-primary-bg-primary widht-100 action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>
            </v-form>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import SimpleMask from "../../components/SimpleMask.vue";
    export default {
        name: 's02-celular',
        props: ['pessoa', 'visible'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            value: "",
            label: "Phone Number",
            focus: false,
            ret: null,
            hasErrors: false,
            rules: {
            },
        }),
        computed: {
        },
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.pessoa.telefone !== undefined && this.pessoa.telefone != null &&
                    (this.pessoa.telefone.tipo !== undefined && this.pessoa.telefone.tipo != null && this.pessoa.telefone.tipo.toString() == "1") &&
                    (this.pessoa.telefone.ddd !== undefined && this.pessoa.telefone.ddd != null) &&
                    (this.pessoa.telefone.numero !== undefined && this.pessoa.telefone.numero != null)
                ) {
                    this.value = (this.pessoa.telefone.ddd + this.pessoa.telefone.numero.replace("-", "")).toString();
                }
            },
            send: function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    celular: value => {
                        if (value !== undefined && value !== null && value != "") {
                            value = value.replace("-", "");
                            value = value.replace("(", "");
                            value = value.replace(")", "");
                            value = value.replace(" ", "");
                            if (value.length < 11 || isNaN(value)) {
                                return "Número inválido";
                            }
                        }
                        else {
                            return "Número inválido";
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.value;
                        if (v !== undefined && v !== null && v != "") {
                            v = v.replace("-", "");
                            v = v.replace(" ", "");
                            this.pessoa.telefone = {};
                            this.pessoa.telefone.ddi = "55";
                            this.pessoa.telefone.ddd = v.substring(0, 2);
                            this.pessoa.telefone.numero = v.substring(2, 11);
                            this.pessoa.telefone.tipo = "1";
                            this.next();
                        }
                    }
                })
            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

