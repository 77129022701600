<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">3-</div>-->
                    <div class="flex-column padding-div">
                        <div class="fs-32px fw-700 color-dark">Você é o responsável do(a) {{getPessoa.nome.split(" ")[0].initCap()}}?</div>
                        <div class="mt-5">
                            <div class="select-boxes-row">
                                <div v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="select(true)">
                                    <p class="mb-0">Sim</p>
                                </div>
                                <div v-bind:class="(selection == false ? 'select-box select-box-2 active' : 'select-box select-box-2')" @click="select(false)">
                                    <p class="mb-0">Não</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>


<script>
    import 'bootstrap';
    import { mapState } from "vuex";
    export default {
        name: 's01-representante',
        props: ['pessoa', 'visible', 'setpessoadata', 'fluxo', 'responsavelpelomenor'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            valInicial: false,
            pessoaCorporativa: null,
            isParticipante: false,
            isRepresentante: false,
            isDependente: false,
            detalhes: null,
            detalhesEndereco: null

        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["pessoaCorporativo"]),
            ...mapState(["token"]),
            ...mapState(["representanteBaseline"]),
            getPessoa() {
                return this.pessoa
            },
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            select(val) {
                this.responsavelpelomenor = val;
                this.next();
            },
            async habilitaOpcoes() {
                this.selection = this.responsavelpelomenor;
            },
            next: function () {

                if (this.responsavelpelomenor) {
                    if (this.representanteBaseline === undefined || this.pessoa["representante"] === undefined) {
                        this.pessoa["representante"] = {};
                    }
                    if (this.representanteBaseline.telefone === undefined) {
                        this.pessoa["representante"].telefone = {};
                    }
                    if (this.representanteBaseline.nome !== undefined) {
                        this.pessoa["representante"].nome = this.representanteBaseline.nome;
                    }
                    if (this.representanteBaseline.cpf !== undefined) {
                        this.pessoa["representante"].cpf = this.representanteBaseline.cpf;
                    }
                    if (this.representanteBaseline["tipo-representacao"] !== undefined) {
                        this.pessoa["representante"]["tipo-representacao"] = this.representanteBaseline["tipo-representacao"];
                    }
                    if (this.representanteBaseline.email !== undefined) {
                        this.pessoa["representante"].email = this.representanteBaseline.email;
                    }
                    if (this.representanteBaseline["data-nascimento"] !== undefined) {
                        this.pessoa["representante"]["data-nascimento"] = this.representanteBaseline["data-nascimento"];
                    }

                    if (this.representanteBaseline !== undefined && this.representanteBaseline.telefone !== undefined && this.representanteBaseline.telefone != null &&
                        (this.representanteBaseline.telefone.tipo !== undefined && this.representanteBaseline.telefone.tipo != null && this.representanteBaseline.telefone.tipo.toString() == "1") &&
                        (this.representanteBaseline.telefone.ddd !== undefined && this.representanteBaseline.telefone.ddd != null) &&
                        (this.representanteBaseline.telefone.numero !== undefined && this.representanteBaseline.telefone.numero != null)
                    ) {
                        this.pessoa.representante.telefone.tipo = this.representanteBaseline.telefone.tipo;
                        this.pessoa.representante.telefone.ddd = this.representanteBaseline.telefone.ddd;
                        this.pessoa.representante.telefone.numero = this.representanteBaseline.telefone.numero;
                    }
                }
                else {
                    if (this.pessoa["representante"] === undefined) {
                        this.pessoa["representante"] = {};
                    }
                    this.pessoa["representante"].telefone = {};
                    this.pessoa["representante"].nome = null;
                    this.pessoa["representante"].cpf = null;
                    this.pessoa["representante"]["tipo-representacao"] = null;
                    this.pessoa["representante"].email = null;
                    this.pessoa["representante"]["data-nascimento"] = null;
                    this.pessoa["representante"].telefone.tipo = "1";
                    this.pessoa["representante"].telefone.ddi = "55";
                    this.pessoa["representante"].telefone.ddd = "";
                    this.pessoa["representante"].telefone.numero = "";
                }


                this.$emit('nextrepresentante', this.responsavelpelomenor);
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

