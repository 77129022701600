import { render, staticRenderFns } from "./s01-email.vue?vue&type=template&id=6a469384&scoped=true&"
import script from "./s01-email.vue?vue&type=script&lang=js&"
export * from "./s01-email.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a469384",
  null
  
)

export default component.exports